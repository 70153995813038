import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from './axiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './App.css';

const Header = ({ onLogout, isAuthenticated }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [userInitials, setUserInitials] = useState('');
  const [error, setError] = useState('');
  const dropdownRef = useRef(null);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    setDropdownOpen(false); // Close initials dropdown when opening mobile menu
  };

  const handleLinkClick = () => {
    setMobileMenuOpen(false);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('/api/user-info/');
        if (response && response.status === 200) {
          const { first_name, last_name } = response.data;
          const initials = `${first_name[0] || ''}${last_name[0] || ''}`.toUpperCase();
          setUserInitials(initials);
        } else {
          setError('Failed to retrieve user initials');
        }
      } catch (error) {
        if (error.response) {
          setError('Unable to fetch user information');
        } else if (error.request) {
          setError('No response from the server. Please try again.');
        } else {
          setError('Network error or server not reachable');
        }
      }
    };

    // Fetch user info only if authenticated
    if (isAuthenticated) {
      fetchUserInfo();
    }
  }, [isAuthenticated]);

  return (
    <header className="navbar">
      <div className="navbar-left">
        {location.pathname === '/login' ? (
          <a href="https://finaanalytics.com/" className="logo-text" onClick={handleLinkClick}>
            <span className="custom-f">F</span>ina.<span className="custom-ai">ai</span>
          </a>
        ) : (
          <Link to="/" className="logo-text" onClick={handleLinkClick}>
            <span className="custom-f">F</span>ina.<span className="custom-ai">ai</span>
          </Link>
        )}
      </div>
      <div className="navbar-right">
        {location.pathname !== '/login' && (
          <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
          </button>
        )}

        {/* Render nav links only if user is authenticated */}
        {isAuthenticated && (
          <nav className={`nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
            <Link to="/features" onClick={handleLinkClick}>Features</Link>

            {/* Show initials dropdown on desktop */}
            {!isMobileMenuOpen && (
              <div className="user-initials-dropdown" ref={dropdownRef}>
                <button onClick={toggleDropdown} className="user-initials-button">
                  {userInitials}
                </button>
                {isDropdownOpen && (
                  <div className="user-initials-dropdown-menu">
                    <button onClick={() => { onLogout(); handleLinkClick(); }}>Logout</button>
                  </div>
                )}
              </div>
            )}

            {/* Show logout button directly in the mobile menu */}
            {isMobileMenuOpen && (
              <button onClick={() => { onLogout(); handleLinkClick(); }} className="logout-button-mobile">
                Logout
              </button>
            )}
          </nav>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}
    </header>
  );
};

export default Header;